<template>
  <div class="form-div">
     <div><Checkbox v-model="isCheck" @on-change="changeCheck">孤独老人信息</Checkbox></div>
     <Form v-show="isCheck" class="isForm" label-position="right" :label-width="150">
        <FormItem class="isNoWidth">
              <span slot="label">孤寡情况:</span>
              <Select clearable v-model="formDataAllone.lonelyType" >
                <Option
                v-for="item in lonelyType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">是否独居:</span>
              <i-switch v-model="formDataAllone.afterManage" true-value='1' false-value="2" >
                <span slot="open">是</span>
                <span slot="close">否</span>
              </i-switch>
              <!-- <RadioGroup v-model="formDataAllone.afterManage">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
             
          </RadioGroup> -->
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">平安通/平安钟:</span>
              <i-switch size="large" v-model="formDataAllone.safeService" true-value='1' false-value="2" >
                <span slot="open">已装</span>
                <span slot="close">未装</span>
            </i-switch>
              <!-- <Select clearable v-model="formDataAllone.safeService" >
                <Option
                v-for="item in safeService"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select> -->
        </FormItem>
        <FormItem class="isNoWidth" v-if="formDataAllone.safeService == '1'">
          <span slot="label" class="validate">服务公司:</span>
          <Input placeholder="请输入服务公司" type="textarea" v-model.trim="formDataAllone.company" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">孤独老人消防情况:</span>
              <RadioGroup v-model="formDataAllone.fireStatus">
                <Radio label="1">
                    暂未发现问题
                </Radio>
                <Radio label="2">
                    有问题
                </Radio>
              </RadioGroup>
        </FormItem>
        <FormItem class="isNoWidth" v-if="formDataAllone.fireStatus == '2'">
              <sapn slot="label" class="validate">消防问题描述:</sapn>
              <Input placeholder="请输入有消防问题描述" type="textarea" v-model.trim="formDataAllone.fireDesc" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">孤独老人备注:</span>
              <Input placeholder="请输入孤独老人备注" type="textarea" v-model="formDataAllone.remark" />
        </FormItem>
     </Form>
  </div>
</template>

<script>
export default {
  props:{
    alloneCheck:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    formDataAllone:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      isCheck:false,
      lonelyType:[
        // {dictKey:'1',dictValue:'孤老'},
        // {dictKey:'2',dictValue:'孤寡'},
        // {dictKey:'3',dictValue:'有子女，无配偶'},
        // {dictKey:'4',dictValue:'无子女，有配偶'},
        // {dictKey:'5',dictValue:'有子女有配偶'},
        // {dictKey:'6',dictValue:'三无孤老'},
      ],
      safeService:[
        {dictKey:'1',dictValue:'是'},
        {dictKey:'2',dictValue:'否'},
      ],
    }
  },
  created(){
    this.getDicType('LONELY_MAN_TYPE','lonelyType')
    this.getDetail()
    if(this.alloneCheck == true){
      this.isCheck = true
    }
  },
  methods:{ 
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeAllone',val)
      // console.log(val);
    },
    // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectArchiveLonely',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  this.$emit('getLonely',res.data)
              }else{
                this.$Message.error({
                  content:'孤独老人信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
  },
   watch:{
     alloneCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>